<template>
  <div class="videoAds">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item
          ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
        >
        <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
        <a-breadcrumb-item>Pack Test</a-breadcrumb-item>
      </template>
    </a-breadcrumb>
    <div class="authoring">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="$route.name !== 'pack-test-launch' ? 'video' : 'pack'"
      />
      <section class="stepAuthor launch-step">
        <div class="info">
          <h2 v-html="langObj['s6-summary'].summaryTitle1"></h2>
          <a-row :gutter="16" class="mb-20">
            <a-col :span="12">
              <ActiveShelf
                :activeShelf="
                  projectSummary !== null ? projectSummary.activeShelf : null
                "
                :showEdit="false"
                :showRetailer="true"
              ></ActiveShelf>
            </a-col>
            <a-col :span="12">
              <SelectedProducts
                :selectedProducts="
                  projectSummary !== null ? projectSummary.chosenProducts : null
                "
                :showEdit="false"
              ></SelectedProducts
            ></a-col>
          </a-row>
          <div>
            <a-descriptions bordered :column="2">
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryProjectName"
              >
                {{ projectSummary !== null ? projectSummary.projectName : "/" }}
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCountry"
              >
                <span
                  v-html="
                    projectSummary !== null ? projectSummary.country : '/'
                  "
                ></span>
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCategory"
              >
                {{
                  projectSummary !== null && "category" in projectSummary
                    ? projectSummary.category
                    : "/"
                }}
              </a-descriptions-item>

              <a-descriptions-item :label="langObj['s6-summary'].summaryBrand">
                <span
                  v-html="projectSummary !== null ? projectSummary.brand : '/'"
                ></span>
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryLegsName"
              >
                <ul v-if="projectSummary !== null" class="mb-0 pl-0">
                  <li
                    v-for="(item, index) in projectSummary.legsName"
                    :key="index"
                  >
                    {{ item }}
                    <br v-if="index < projectSummary.legsName.length" />
                  </li>
                </ul>
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryDeepDiveProducts"
              >
                <ul v-if="projectSummary !== null" class="mb-0 pl-0">
                  <li
                    v-for="(item, index) in projectSummary.deepDiveProductsName"
                    :key="index"
                  >
                    {{ item
                    }}<span
                      v-if="
                        index + 1 < projectSummary.deepDiveProductsName.length
                      "
                      >,</span
                    >
                    <br
                      v-if="index < projectSummary.deepDiveProductsName.length"
                    />
                  </li>
                </ul>
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCommunicationObjective"
              >
                {{
                  projectSummary !== null
                    ? projectSummary.chosenCommunicationObjective1
                        .CommunicationObjectiveName
                    : "/"
                }}<span
                  v-if="
                    projectSummary !== null &&
                    projectSummary.chosenCommunicationObjective2 !== -1
                  "
                  >,</span
                >
                {{
                  projectSummary !== null
                    ? projectSummary.chosenCommunicationObjective2
                        .CommunicationObjectiveName
                    : "/"
                }}
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summarySampleSizePerLeg"
              >
                <div class="flex items-center sample-size">
                  <div
                    @click="openSampleSize = !openSampleSize"
                    :class="{ disabled: !changeSampleSize }"
                    @focusout="openSampleSize = false"
                  >
                    <a-select
                      class="sample-size__select"
                      :class="{
                        'sample-size__select--disabled': !changeSampleSize,
                      }"
                      @change="selectSampleSize"
                      :default-value="sampleSize"
                      :disabled="!changeSampleSize"
                      :open="openSampleSize"
                      :dropdownMatchSelectWidth="false"
                      :key="Math.random()"
                    >
                      <a-select-option
                        v-for="(item, index) in [75, 100, 150, 200, 250, 300]"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <a-button
                    type="primary"
                    class="btn-change-sample"
                    v-if="!changeSampleSize"
                    @click="(openSampleSize = true), (changeSampleSize = true)"
                  >
                    Change</a-button
                  >
                  <a-button
                    v-else
                    type="primary"
                    class="btn-change-sample"
                    @click="
                      (openSampleSize = false), (changeSampleSize = false)
                    "
                    >Close</a-button
                  >
                </div>
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCostCenter"
              >
                {{
                  editableSurvey !== null
                    ? editableSurvey.SurveyConfig.CostCenter !== ""
                      ? editableSurvey.SurveyConfig.CostCenter
                      : "/"
                    : "/"
                }}
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryEstimateCompletionDate"
              >
                {{ estimateCompletionDate }}
              </a-descriptions-item>
            </a-descriptions>

            <div class="launch-step__total">
              <div class="agreement">
                <h4 v-html="langObj['s6-summary'].summaryTitle2"></h4>
                <a-checkbox v-model="privacyCheck">
                  <span v-html="langObj['s6-summary'].summaryPrivacy"></span>
                </a-checkbox>
                <span>
                  <a
                    href="#"
                    v-html="langObj['s6-summary'].summaryPrivacyLink"
                  ></a>
                </span>
              </div>
              <a-descriptions bordered :column="2">
                <a-descriptions-item
                  :label="langObj['s6-summary'].summaryTotalPrice"
                >
                  <span class="flex items-center">
                    ${{ getPackTestPrice }}
                    <a-tooltip
                      placement="top"
                      overlayClassName="ant-tooltip--small ant-tooltip--step-nav"
                    >
                      <template slot="title">
                        <span
                          v-html="langObj['s6-summary'].summarySampleSizeInfo"
                        ></span>
                      </template>

                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
        </div>
      </section>
      <form :action="apiUrl" method="POST" ref="payLaunchForm">
        <input type="hidden" name="SurveyId" id="SurveyId" :value="surveyID" />
        <input type="hidden" name="Price" id="Price" :value="finalPrice" />
      </form>

      <StepNaviBar
        :stepIndex="stepIndex"
        :nextDisable="false"
        @nextHandler="nextStep"
        :isEnd="true"
        :prevDisable="false"
        @prevHandler="prevStep"
        :accountPaymentType="accountPaymentType"
        :loading="
          loading ||
          getPriceLoader ||
          finalPriceCalculationLoader ||
          getCheckingFeasibilityLoader
        "
        prevBtnTooltip="Back to Brand Strategy"
        :conciergeAssistance="$route.name === 'pack-test-launch' ? true : false"
        :stepsType="$route.name === 'pack-test-launch' ? 'pack' : 'video'"
      />
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import ActiveShelf from "@/components/packTestSurvey/ActiveShelf.vue";
import SelectedProducts from "@/components/packTestSurvey/SelectedProducts.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import { store } from "@/utils/store.js";

export default {
  name: "Summary",
  components: {
    ProgressBar,
    StepNaviBar,
    ActiveShelf,
    SelectedProducts,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      privacyCheck: true,
      stepIndex: 6,
      surveyID: 0,
      client: {},
      projectSummary: null,
      langObj: {},
      price: null,
      accountPaymentType: null,
      equalPrices: false,
      loading: false,
      apiUrl: null,
      surveyTypeID: null,
      finalPrice: null,
      sampleSize: 100,
      openSampleSize: false,
      changeSampleSize: false,
      user: null,
      editableSurvey: null,
      finalPriceCalculationLoader: false,
      estimateCompletionDate: null,
    };
  },
  computed: {
    getPackTestPrice() {
      return store.surveyPackTestPrice;
    },
    getPriceLoader() {
      return store.priceLoader.length > 0;
    },
    getCheckingFeasibilityLoader() {
      return store.checkingFeasibilityLoader.length > 0;
    },
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: "communication-objective",
        params: { id: this.surveyID },
      });
    },
    launchSurvey() {
      const launchPromise = new Promise((resolve) => {
        wsUtils.PackTestLaunchSurvey(
          { SurveyId: this.surveyID, userId: this.user.EncrypteduserID },
          resolve
        );
      });

      launchPromise.then(() => {
        let currentSummaryData = jsUtils.getSurveySummaryData();
        jsUtils.setSurveySummaryData({
          ...currentSummaryData,
          surveyPackTestPrice: store.surveyPackTestPrice,
        });
        this.$router.push({
          name: "launch",
          params: { id: this.$route.params.id },
        });
        // this.$message.success(data, 6);
      });
    },
    nextStep() {
      let runSurveyMode = jsUtils.getRunSurveyMode();
      let feasibilitySuccess = jsUtils.getFeasibilitySuccess();
      let runMessage = "";

      if (!runSurveyMode && !feasibilitySuccess) {
        runMessage =
          "You can't 'Pay & Launch' study because you haven't filled in all the information for the Pack test and Feasibility not confirmed.";
      } else if (!feasibilitySuccess) {
        runMessage = "Feasibility not confirmed. Please, contact us.";
      } else {
        runMessage =
          "You can't 'Pay & Launch' study because you haven't filled in all the information for the Pack test.";
      }

      if (!runSurveyMode || !feasibilitySuccess) {
        this.$notification["warning"]({
          message: `Oops`,
          description: runMessage,
          placement: "bottomLeft",
          duration: 10,
        });
      } else {
        if (this.equalPrices) {
          jsUtils.setSurveysApiTrigger(true);
          if (this.accountPaymentType === "Invoiced") {
            this.launchSurvey();
          } else {
            this.loading = true;
            this.$refs.payLaunchForm.submit();
          }
        } else {
          this.$router.push({
            name: "global-error-page",
            params: {
              id: "1321-12423",
              surveyId: this.surveyID,
              description: `Pack test - Survey ID: ${this.surveyID}, Cliend ID: ${this.client.ClientId}, Client Name: ${this.client.ClientName}, Total Price - Front end calculation: ${this.getPackTestPrice}, Final Price - Back end calculation: ${this.finalPrice}`,
            },
          });
        }
      }
    },
    selectSampleSize(size) {
      this.openSampleSize = false;
      this.sampleSize = size;

      const saveSampleSize = new Promise((resolve) => {
        wsUtils.SaveSampleSize(
          {
            surveyId: this.$route.params.id,
            sampleSize: size,
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      saveSampleSize.then((data) => {
        if (data.Success === true) {
          this.$notification["success"]({
            message: "Sample Updated!",
            description:
              "Your sample and project costs have been updated successfully.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.changeSampleSize = false;
          this.priceCalculation();
          this.getSurveyData();
        } else if (data.Status === 0) {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 6,
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "The feasibility of this study is at risk or not feasible. Please choose less sample per deep dive.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      });
    },
    priceCalculation() {
      let currentPackTestPriceData = jsUtils.getPackTestPrice();
      jsUtils.setPackTestPrice({
        ...currentPackTestPriceData,
        explicitSamplePerDeepDive: this.sampleSize,
      });
      this.getPackTestTotalPrice();
      this.getPackTestFinalPrice();
    },
    getPackTestFinalPrice() {
      this.finalPriceCalculationLoader = true;
      const GetPackTestFinalPrice = new Promise((resolve) => {
        wsUtils.GetPackTestFinalPrice(
          {
            surveyId: this.$route.params.id,
          },
          resolve
        );
      });
      GetPackTestFinalPrice.then((data) => {
        this.finalPrice = data.total;
        if (this.getPackTestPrice === data.total) {
          this.equalPrices = true;
        } else {
          this.equalPrices = false;
        }
        this.finalPriceCalculationLoader = false;
      });
    },
    getSurveyData() {
      let wsType = "GetPackTestSurveyDetails";
      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: this.$route.params.id,
            SurveyTypeID: jsUtils.getSurveyTypeId(),
          },
          resolve
        );
      });
      surveyPromise.then((data) => {
        jsUtils.setEditableSurvey(data);
      });
    },
    setDefaultSampleSize() {
      const saveSampleSize = new Promise((resolve) => {
        wsUtils.SaveSampleSize(
          {
            surveyId: this.$route.params.id,
            sampleSize: 100,
            userId: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      saveSampleSize.then(() => {});
    },
    getDateAfter10Days() {
      const today = new Date();
      const futureDate = new Date(today.getTime() + 10 * 24 * 60 * 60 * 1000);
      this.estimateCompletionDate = futureDate.toLocaleDateString();
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
  },
  activated() {
    this.surveyID = this.$route.params.id;
    this.user = jsUtils.getUserInfo();
    this.editableSurvey = jsUtils.getEditableSurvey();
    if (this.editableSurvey.SurveyConfig.SurveyStatus !== "Draft") {
      this.$router.push("/");
    }
    this.sampleSize = this.editableSurvey.audience.sampleSize;
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    if (
      window.location.hostname === "app.hotspexlabs.com" ||
      window.location.hostname === "pg.hotspexlabs.com" ||
      window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
      window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
      window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
    ) {
      this.apiUrl =
        "https://admin.hotspexlabs.com/Cart/create-checkout-session";
    } else {
      this.apiUrl =
        "https://staging-admin.hotspexstudio.com/Cart/create-checkout-session";
    }

    this.projectSummary = jsUtils.getSurveySummaryData();
    this.accountPaymentType = jsUtils.getAccountPaymentType();

    this.getPackTestFinalPrice();
    this.getDateAfter10Days();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "launch") {
      window.open("https://vpt.pg.com", "_self", "noreferrer");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.info {
  h2 {
    font-size: 16px;
    font-weight: 600;
  }
}
.stepAuthor {
  background-color: white;
  padding: 1em;
  display: block;
  width: 100%;
  margin: auto;
  max-width: 920px;
  ::v-deep .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 16px 15px;
    width: 154px;
    max-width: 154px;
  }
  ::v-deep .ant-descriptions-item-content {
    width: 265px;
  }
  // ::v-deep .ant-descriptions-row {
  //   &:last-child {
  //     th {
  //       white-space: nowrap;
  //     }
  //   }
  // }
}

.agreement {
  padding-right: 20px;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  a {
    font-size: 14px;
    color: #1890ff;
    text-decoration: underline;
    margin-left: -5px;
  }
}

.btn-change-sample {
  height: 22px;
  padding: 0 7px;
  font-size: 13px;
  margin-left: 8px;
}

.sample-size {
  &__select {
    width: 48px;
    ::v-deep .ant-select-arrow {
      pointer-events: none;
      font-size: 9px;
      right: 4px;
    }
    ::v-deep .ant-select-selection__rendered {
      margin-right: 0;
      margin-left: 6px;
    }
    &--disabled {
      ::v-deep .ant-select-selection {
        border-color: transparent;
        background: transparent;
        color: rgba(0, 0, 0, 0.65);
      }
      ::v-deep .ant-select-arrow {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }
  }
  .ant-btn {
    width: 60px;
    margin-left: 5px;
  }
}

.disabled {
  pointer-events: none;
}
</style>

<style lang="scss">
.ant-descriptions-item-label {
  font-weight: 500;
  background-color: #f6fcff;
  color: var(--hsgrey);
}

.launch-step {
  padding-bottom: 80px !important;
  &__video-info {
    margin: 40px 0 0;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    border-radius: 4px;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-right: 1px solid #e8e8e8;
      padding: 16px 24px;
      &:last-child {
        border-right: 0;
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: #fff !important;
          }
        }
      }
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .ant-descriptions {
      max-width: 260px;
      width: 100%;
      .anticon {
        margin-left: 8px;
        display: block;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
</style>
