<template>
  <div class="videoAds">
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item
        ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
      <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="authoring">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-launch'
            ? 'video'
            : 'pack'
        "
      />
      <section class="stepAuthor launch-step">
        <div class="info">
          <div>
            <a-descriptions
              :title="langObj['s6-summary'].summaryTitle1"
              bordered
              :column="2"
            >
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryProjectName"
              >
                {{ projectSummary !== null ? projectSummary.projectName : "/" }}
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCategory"
              >
                {{
                  projectSummary !== null && "category" in projectSummary
                    ? projectSummary.category
                    : "/"
                }}
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCommunicationObjective"
                v-if="surveyTypeID === 93"
              >
                {{
                  projectSummary !== null
                    ? projectSummary.chosenCommunicationObjective1
                        .CommunicationObjectiveName
                    : "/"
                }}<span
                  v-if="projectSummary.chosenCommunicationObjective2 !== -1"
                  >,</span
                >
                {{
                  projectSummary !== null
                    ? projectSummary.chosenCommunicationObjective2
                        .CommunicationObjectiveName
                    : "/"
                }}
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryDeepDiveProducts"
                v-if="surveyTypeID === 93"
              >
                <ul v-if="projectSummary !== null" class="mb-0 pl-0">
                  <li
                    v-for="(item, index) in projectSummary.deepDiveProductsName"
                    :key="index"
                  >
                    {{ item }}
                    <br
                      v-if="index < projectSummary.deepDiveProductsName.length"
                    />
                  </li>
                </ul>
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summaryRetailer"
                v-if="surveyTypeID === 93"
              >
                <span
                  v-html="
                    projectSummary !== null ? projectSummary.shelfRetailer : '/'
                  "
                ></span>
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryAudience"
                v-else
              >
                <span
                  v-html="
                    projectSummary !== null ? projectSummary.audience : '/'
                  "
                ></span>
              </a-descriptions-item>

              <a-descriptions-item
                :label="langObj['s6-summary'].summaryTotalSampleSize"
              >
                {{
                  projectSummary !== null
                    ? projectSummary.sampleSize *
                      (projectSummary.creativesCount || 1)
                    : "/"
                }}
              </a-descriptions-item>
              <a-descriptions-item
                :label="langObj['s6-summary'].summaryCustomQuestions"
                :span="2"
                v-if="surveyTypeID !== 93"
              >
                <template v-if="projectSummary !== null">
                  <ol class="mb-0 pl-15">
                    <li
                      v-for="(item, index) in projectSummary.customQuestions"
                      :key="index"
                    >
                      {{ item }}
                    </li>
                  </ol>
                  <span v-if="projectSummary.customQuestions.length === 0"
                    >/</span
                  >
                </template>
              </a-descriptions-item>
            </a-descriptions>

            <a-table
              :data-source="videoItems"
              :pagination="{ hideOnSinglePage: true }"
              class="launch-step__video-info"
              v-if="surveyTypeID !== 92 && surveyTypeID !== 93"
            >
              <a-table-column
                width="45%"
                key="name"
                :title="langObj['s6-summary'].summaryVideoName"
                data-index="name"
              />
              <a-table-column
                key="thumbnail"
                :title="langObj['s6-summary'].summaryThumbnail"
                data-index="thumbnail"
                width="18.33%"
              >
                <template slot-scope="text, record">
                  <img :src="record.coverImage" alt="" width="120" />
                </template>
              </a-table-column>
              <a-table-column
                key="duration"
                :title="langObj['s6-summary'].summaryDuration"
                data-index="duration"
                width="18.33%"
              />
              <a-table-column
                key="sampleSize"
                :title="langObj['s6-summary'].summarySampleSize"
                data-index="sampleSize"
                width="18.33%"
              />
            </a-table>

            <div class="launch-step__total">
              <div class="agreement">
                <h4 v-html="langObj['s6-summary'].summaryTitle2"></h4>
                <a-checkbox v-model="privacyCheck">
                  <span v-html="langObj['s6-summary'].summaryPrivacy"></span>
                </a-checkbox>
                <span>
                  <a
                    href="#"
                    v-html="langObj['s6-summary'].summaryPrivacyLink"
                  ></a>
                </span>
              </div>
              <a-descriptions bordered :column="2">
                <a-descriptions-item
                  :label="langObj['s6-summary'].summaryTotalPrice"
                >
                  <span class="flex items-center">
                    ${{ price }}
                    <a-tooltip
                      placement="top"
                      overlayClassName="ant-tooltip--small"
                    >
                      <template slot="title">
                        <span
                          v-html="langObj['s6-summary'].summaryPriceTooltip"
                        ></span>
                      </template>

                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
        </div>
      </section>
      <form :action="apiUrl" method="POST" ref="payLaunchForm">
        <input type="hidden" name="SurveyId" id="SurveyId" :value="surveyID" />
        <input type="hidden" name="Price" id="Price" :value="price" />
      </form>

      <StepNaviBar
        :stepIndex="stepIndex"
        :nextDisable="false"
        @nextHandler="nextStep"
        :isEnd="true"
        :prevDisable="false"
        @prevHandler="prevStep"
        :accountPaymentType="accountPaymentType"
        :loading="loading"
        :prevBtnTooltip="backBtnTooltip"
        :conciergeAssistance="$route.name === 'pack-test-launch' ? true : false"
        :stepsType="
          surveyTypeID === 102
            ? 'Reticle Alignment Test'
            : $route.name !== 'pack-test-launch'
            ? 'video'
            : 'pack'
        "
      />
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import { store } from "@/utils/store.js";

export default {
  name: "Summary",
  components: {
    ProgressBar,
    StepNaviBar,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      privacyCheck: true,
      stepIndex: 6,
      surveyID: 0,
      client: {},
      projectSummary: null,
      langObj: {},
      price: null,
      accountPaymentType: null,
      videoDetailsRows: null,
      videoItems: null,
      equalPrices: false,
      loading: false,
      apiUrl: null,
      surveyTypeID: null,
      finalPrice: null,
      breadcrumbTitle: "",
      backBtnTooltip: "",
    };
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: this.$route.meta.launchPrevRoute || "vid-audience",
        params: { id: this.surveyID },
      });
    },
    launchSurvey() {
      const launchPromise = new Promise((resolve) => {
        wsUtils.LaunchSurvey(this.surveyID, resolve);
      });

      launchPromise.then((data) => {
        this.$router.push({
          name: "home",
        });
        this.$message.success(data, 6);
      });
    },
    nextStep() {
      let runSurveyMode = jsUtils.getRunSurveyMode();

      if (runSurveyMode === false) {
        this.$notification["warning"]({
          message: `Oops`,
          description:
            "You can't 'Pay & Launch' study because you haven't filled in all the information for the Pack test.",
          placement: "bottomLeft",
          duration: 10,
        });
      } else {
        if (this.equalPrices) {
          jsUtils.setSurveysApiTrigger(true);
          if (this.accountPaymentType === "Invoiced") {
            this.launchSurvey();
          } else {
            this.loading = true;
            this.$refs.payLaunchForm.submit();
          }
        } else {
          this.$router.push({
            name: "global-error-page",
            params: {
              id: "1321-12423",
              surveyId: this.surveyID,
              description: `Survey ID: ${this.surveyID}, Cliend ID: ${this.client.ClientId}, Client Name: ${this.client.ClientName}, Total Price - Front end calculation: ${this.price}, Final Price - Back end calculation: ${this.finalPrice}`,
            },
          });
        }
      }
    },
    setTotalPrice(price) {
      this.price = store.surveyTotalPrice;

      let surveySummary = {
        price: price,
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });

      this.getFinalSurveyPrice();
    },
    getFinalSurveyPrice() {
      const GetFinalSurveyPrice = new Promise((resolve) => {
        wsUtils.GetFinalSurveyPrice(this.$route.params.id, resolve);
      });
      GetFinalSurveyPrice.then((data) => {
        this.finalPrice = data;
        if (this.price === data) {
          this.equalPrices = true;
        } else {
          this.equalPrices = false;
        }
      });
    },
    getBackButtonTooltip(routeName) {
      let backButtonTooltip;
      switch (routeName) {
        case "vid-yt-launch":
          backButtonTooltip = "Back to Key Metrics";
          break;
        case "vid-ua-launch":
          backButtonTooltip = "Back to Customize";
          break;
        default:
          backButtonTooltip = "Back to Audience";
          break;
      }
      return backButtonTooltip;
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
  },
  activated() {
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.backBtnTooltip = this.getBackButtonTooltip(this.$route.name);
    this.surveyID = this.$route.params.id;
    if (window.location.hostname === "app.hotspexlabs.com") {
      this.apiUrl =
        "https://admin.hotspexlabs.com/Cart/create-checkout-session";
    } else {
      this.apiUrl =
        "https://staging-admin.hotspexstudio.com/Cart/create-checkout-session";
    }

    this.projectSummary = jsUtils.getSurveySummaryData();

    this.accountPaymentType = jsUtils.getAccountPaymentType();
    this.videoItems =
      this.projectSummary.creatives !== undefined
        ? this.projectSummary.creatives.map((item, index) => {
            return {
              ...item,
              sampleSize: this.projectSummary.sampleSize,
              key: index,
            };
          })
        : null;

    this.getSurveyTotalPrice();
    this.setTotalPrice();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.breadcrumbTitle =
      this.$route.name !== "pack-test-launch" ? "Video Ad" : "Pack";
  },
};
</script>

<style lang="scss" scoped>
.stepAuthor {
  background-color: white;
  padding: 1em;
  display: block;
  width: 60vw;
  margin: auto;
}

.agreement {
  padding-right: 20px;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  a {
    font-size: 14px;
    color: #1890ff;
    text-decoration: underline;
    margin-left: -5px;
  }
}
</style>

<style lang="scss">
.ant-descriptions-item-label {
  font-weight: 500;
  background-color: #f6fcff;
  color: var(--hsgrey);
}

.launch-step {
  padding-bottom: 80px !important;
  &__video-info {
    margin: 40px 0 0;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    border-radius: 4px;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-right: 1px solid #e8e8e8;
      padding: 16px 24px;
      &:last-child {
        border-right: 0;
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: #fff !important;
          }
        }
      }
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .ant-descriptions {
      max-width: 260px;
      width: 100%;
      .anticon {
        margin-left: 8px;
        display: block;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
</style>
